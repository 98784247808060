@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact {
  &__wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 550px;
    gap: rem(30);
  }

  &__text {
    >*:not(:last-child) {
      margin-bottom: rem(30);
    }
  }

  &__box {
    display: flex;
    flex-direction: column;

    span {
      font-weight: var(--fw-600);
    }

    p,
    a {
      font-weight: var(--fw-500);
      color: var(--color-gray-400);
    }

    a {

      &:hover,
      &:focus {
        color: var(--color-orange-400);
      }
    }
  }

  &__form {
    border-radius: var(--radius-main);
    padding: 30px;
    background-color: var(--color-gray-100);

    .login input {
      position: relative;
      display: block;
      overflow: hidden;
      margin-bottom: 30px;
      border: 1px solid var(--color-gray-400);
      border-radius: var(--radius-main);
      padding: 10px 15px;
      width: 100%;
    }

    label:not(.login) {
      position: relative;
      display: block;
      margin-bottom: 30px;
      border: 1px solid var(--color-gray-400);
      border-radius: var(--radius-main);
      padding: 10px 15px;
      width: 100%;

      &:not(:nth-child(n+4)) {
        &::before {
          content: "";
          position: absolute;
          right: 20px;
          top: 50%;
          width: 10px;
          height: 8px;
          background-image: var(--arrow);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          translate: 0 -50%;
        }
      }

      >* {
        width: 100%;
      }
    }

    select {
      position: relative;
      background-color: inherit;
    }

    .btn {
      max-width: 100%;
    }

    &.hide {
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }
  }

  .contact-icon {
    &::after {
      background-color: rgba(255, 255, 255, 0.9);
      filter: invert(1);
    }
  }

  .show-login {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.3s;

    &.show-message {
      position: relative;
      opacity: 1;
      visibility: visible;
    }
  }

  &--login {
    h1 {
      margin-bottom: 30px;
      font-size: clamp(1.75rem, 1.6rem + 0.75vi, 2.5rem);
    }

    h2 {
      font-size: clamp(1.2rem, 1rem + 0.2vi, 1.3rem);
      line-height: 1.6;
    }

    .container {
      max-width: 800px;
    }
  }

  @include media(tablet-wide) {
    &__wrapper {
      grid-template-columns: 400px 1fr;
    }
  }

  @include media(tablet) {
    &__wrapper {
      grid-template-columns: 300px 1fr;
    }
  }

  @include media(mobile-l) {
    &__wrapper {
      grid-template-columns: 1fr;
      gap: 50px;
    }

    &__form {
      padding: 15px;
    }
  }
}