@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.change {
  &__grid {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    .doc-link {
      display: flex;
      justify-content: flex-end;
      gap: rem(30);
    }

    a {
      font-weight: var(--fw-600);
      font-size: 20px;
    }
  }


  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-orange-400);
    border-radius: calc(var(--radius-main) * 2);
    padding: rem(30) 25px;
    height: 100%;
    background-color: var(--color-white);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-image: url('../images/icons/lightning.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.06;
    }
  }

  ul {
    padding-left: 30px;
  }

  li {
    position: relative;

    &::before {
      @include contain-mask;

      content: "";
      position: absolute;
      left: -32px;
      top: 0;
      mask-image: url("../images/icons/lightning.svg");
      width: 25px;
      height: 25px;
      background-color: var(--color-orange-400);
      transform: skew(-16deg, -5deg);
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    ul {
      li:first-child {
        margin-top: 20px;
      }
    }
  }

  >*:not(:last-child) {
    margin-bottom: rem(40);
  }

}