@font-face {
  src: url("../ArtegraSans-Regular.941eaef8.woff2") format("woff2");
  font-family: ArtegraSans;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../ArtegraSans-Medium.435a0a70.woff2") format("woff2");
  font-family: ArtegraSans;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../ArtegraSans-SemiBold.eda5e556.woff2") format("woff2");
  font-family: ArtegraSans;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../ArtegraSans-Bold.7af79836.woff2") format("woff2");
  font-family: ArtegraSans;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url("../ArtegraSans-Black.3329af65.woff2") format("woff2");
  font-family: ArtegraSans;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --content-width: 1280px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "ArtegraSans", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-900: 900;
  --color-white: #fff;
  --color-black: #000;
  --color-dark-400: #15141c;
  --color-orange-400: #ff7617;
  --color-gray-100: #f7f7f7;
  --color-gray-400: #616161;
  --orange-filter: brightness(0) saturate(100%) invert(76%) sepia(58%) saturate(6927%) hue-rotate(350deg) brightness(105%) contrast(101%);
  --radius-main: 8px;
  --gradient: linear-gradient(89.88deg, #00000087 59.48%, #0000004e 78.99%, #0000 99.89%);
  --arrow: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.390524 0.723858C-0.130175 1.24456 -0.130175 2.08878 0.390524 2.60948L11.0572 13.2761C11.5779 13.7968 12.4221 13.7968 12.9428 13.2761L23.6095 2.60948C24.1302 2.08878 24.1302 1.24456 23.6095 0.723858C23.0888 0.203159 22.2446 0.203159 21.7239 0.723858L12 10.4477L2.27614 0.723858C1.75544 0.203159 0.911223 0.203159 0.390524 0.723858Z' fill='%23000'/%3E%3C/svg%3E%0A");
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-dark-400);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.2;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page__body.overflow:before {
  z-index: 10;
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.logo {
  width: 100%;
  max-width: 200px;
  margin-right: 20px;
  display: block;
}

.logo img {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .logo {
    max-width: 160px;
  }
}

img {
  border-radius: var(--radius-main);
  overflow: hidden;
}

section {
  padding: 5rem 0;
}

@media only screen and (max-width: 992px) {
  section {
    padding: 2.5rem 0;
  }
}

.overflow {
  position: relative;
}

.overflow:before {
  content: "";
  background-color: var(--color-black);
  opacity: .6;
  position: absolute;
  inset: 0;
}

.slogan {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-500);
  color: var(--color-orange-400);
  margin-block-end: 15px;
  padding-inline: 30px;
  font-size: 18px;
  display: inline-block;
  position: relative;
}

.slogan:before, .slogan:after {
  content: "";
  width: 25px;
  height: 25px;
  background-color: currentColor;
  position: absolute;
  top: 50%;
  transform: skew(-16deg, -5deg)translate3d(0, -50%, 0);
  -webkit-mask-image: url("../images/icons/lightning.svg");
  mask-image: url("../images/icons/lightning.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.slogan:before {
  left: 0;
}

.slogan:after {
  right: 0;
}

@media only screen and (max-width: 576px) {
  .slogan {
    width: auto;
    text-align: center;
  }
}

.lightning {
  position: relative;
}

.lightning:before {
  content: "";
  width: 100%;
  height: 100%;
  max-width: 320px;
  background-color: var(--color-orange-400);
  position: absolute;
  -webkit-mask-image: url("../images/icons/lightning.svg");
  mask-image: url("../images/icons/lightning.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.background-image {
  position: relative;
}

.background-image:before {
  content: "";
  z-index: -1;
  opacity: .05;
  background-image: url("../images/home/about-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  inset: 0;
}

.selected {
  color: var(--color-white);
  background-color: var(--color-orange-400);
  padding-block: 2.5rem;
  padding-inline: 2.5rem 5.625rem;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

.selected:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: currentColor;
  position: absolute;
  top: 50%;
  transform: skew(-16deg, -5deg)translate3d(-50px, -15%, 0);
  -webkit-mask-image: url("../images/icons/lightning.svg");
  mask-image: url("../images/icons/lightning.svg");
  -webkit-mask-position: 100%;
  mask-position: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media only screen and (max-width: 992px) {
  .selected {
    padding: 20px;
    padding-right: 80px;
  }

  .selected:before {
    translate: 30px -15%;
  }
}

@media only screen and (max-width: 576px) {
  .selected:before {
    translate: 90px -54%;
  }
}

.error-message {
  opacity: 0;
  visibility: hidden;
  color: red;
  position: absolute;
  bottom: -27px;
  left: 10px;
}

.error .error-message {
  opacity: 1;
  visibility: visible;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-700);
  color: inherit;
  line-height: 1.2;
  overflow: hidden;
}

h1 {
  font-size: clamp(2rem, 1.8rem + 1vi, 3rem);
}

h2, .title {
  font-size: clamp(1.75rem, 1.6rem + .75vi, 2.5rem);
}

@media only screen and (max-width: 475px) {
  h2, .title {
    font-size: 24px;
  }
}

h3, .title--sec, .help h2 {
  font-size: clamp(1.2rem, 1rem + .2vi, 1.3rem);
}

a {
  transition: color .3s;
}

a:hover, a:focus {
  color: var(--color-orange-400);
}

.header {
  z-index: 10;
  width: 100%;
  box-shadow: 0 0 15px var(--color-gray-400);
  background-color: var(--color-white);
  margin: 0 auto;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header__top {
  color: var(--color-white);
  background-color: var(--color-dark-400);
  padding: 15px 0;
}

.header__inner {
  align-items: center;
  font-size: 15px;
  display: flex;
}

.header__inner .logo {
  margin-right: auto;
}

.header__contact {
  flex-direction: column;
  display: flex;
}

.header__contact:not(:last-child) {
  margin-right: 1.875rem;
}

.header__contact span:first-child {
  margin-bottom: 5px;
}

.header__contact--phone {
  flex-direction: row;
}

.header__contact--phone a:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 1280px) {
  .header__contact--phone a:not(:last-child) {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1180px) {
  .header__contact--phone a:not(:last-child) {
    margin-right: 0;
  }
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

.header.hide.scroll {
  transform: translateY(0);
}

@media only screen and (max-width: 1180px) {
  .header {
    display: flex;
  }

  .header__top {
    width: 100%;
    flex-shrink: 0;
    padding-right: 50px;
  }

  .header__contact--phone {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .header__contact {
    display: none;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    max-width: 350px;
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  margin-right: auto;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .header .nav__list {
    z-index: -1;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 30px;
  }
}

.nav__item {
  position: relative;
}

.nav__item:not(:last-of-type) {
  margin-right: 13px;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 0;
  }

  .nav__item:not(.nav__dropdown li) {
    margin-bottom: 20px;
    padding: 0;
  }
}

.nav__item--dropdown {
  padding-right: 5px;
}

.nav__item--dropdown span {
  position: relative;
}

.nav__item--dropdown span:before {
  content: "";
  width: 10px;
  height: 8px;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: rotate .2s, filter .2s;
  position: absolute;
  top: 50%;
  right: -5px;
  translate: 0 -50%;
}

@media only screen and (max-width: 1180px) {
  .nav__item--dropdown span:before {
    right: -15px;
  }
}

.nav__item--dropdown.open span:before {
  filter: var(--orange-filter);
  rotate: 180deg;
}

.nav__item--dropdown.open span {
  color: var(--color-orange-400);
}

.nav__item--dropdown.open .nav__dropdown {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1180px) {
  .nav__item--dropdown.open .nav__dropdown {
    display: block;
  }
}

.nav__item--dropdown:hover:before, .nav__item--dropdown:focus:before {
  filter: var(--orange-filter);
  rotate: 180deg;
}

@media only screen and (max-width: 1180px) {
  .nav__item--dropdown {
    text-align: center;
  }
}

.nav__link {
  letter-spacing: .035em;
  white-space: nowrap;
  color: var(--color-dark-450);
  height: max-content;
  cursor: pointer;
  background-color: #0000;
  padding: 0 10px 0 0;
  font-size: 18px;
  transition-property: color;
  transition-duration: .2s;
  display: inline-flex;
}

.nav__link:hover, .nav__link:focus, .nav__link.true {
  color: var(--color-orange-400);
  text-underline-offset: 5px;
  text-decoration: underline;
}

@media only screen and (max-width: 1280px) {
  .nav__link {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1180px) {
  .nav__link {
    padding: 0;
  }
}

.nav__dropdown {
  border: 1px solid var(--color-orange-400);
  background-color: var(--color-white);
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: .2s;
  position: absolute;
  top: 100%;
}

.nav__dropdown .nav__link {
  padding: 10px;
}

@media only screen and (max-width: 1180px) {
  .nav__dropdown {
    margin: 0 auto;
    display: none;
    position: relative;
    top: 0;
  }
}

[class].btn {
  border-radius: var(--radius-main);
  width: 100%;
  max-width: 220px;
  font-weight: var(--fw-600);
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-orange-400);
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  font-size: 16px;
  line-height: 1.2;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
}

[class].btn:hover, [class].btn:focus, [class].btn--reverse {
  border-color: var(--color-orange-400);
  color: var(--color-orange-400);
  background-color: var(--color-white);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  border-color: var(--color-orange-400);
  color: var(--color-white);
  background-color: var(--color-orange-400);
}

[class].btn--tab {
  width: 100%;
  max-width: 100%;
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .2s;
  --lines-color: var(--color-white);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 30px;
  translate: 0 50%;
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 1180px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened .burger span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened .burger span:before, .opened .burger span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened .burger span:before {
  animation-name: toplinecross;
}

.opened .burger span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.footer {
  color: var(--color-white);
  background-color: var(--color-dark-400);
  padding: 1.875rem 0;
}

.footer__top {
  justify-content: space-between;
  margin-bottom: 1.875rem;
  display: flex;
}

.footer__bottom {
  text-align: center;
  font-size: 14px;
}

.footer .logo {
  max-width: 230px;
}

@media only screen and (max-width: 992px) {
  .footer__top {
    flex-direction: column;
    align-items: center;
  }

  .footer .nav__list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}

.faq__item {
  background-color: var(--color-white);
}

.faq__item:not(:last-child) {
  margin-bottom: 32px;
}

.faq details {
  overflow: hidden;
}

.faq details p {
  font-weight: var(--fw-500);
  font-size: 20px;
}

.faq details[open] summary {
  border-color: var(--color-orange-400);
  color: var(--color-white);
  background-color: var(--color-orange-400);
}

.faq details[open] summary:before {
  filter: brightness(0) saturate() invert() sepia() saturate(2%) hue-rotate(58deg) brightness(102%) contrast(101%);
  rotate: unset;
}

.faq summary {
  border-style: solid;
  border-width: 1px 1px 0;
  border-color: var(--color-gray-100);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-600);
  background-color: var(--color-gray-100);
  cursor: pointer;
  padding: 16px 50px 16px 24px;
  font-size: 20px;
  line-height: 1.2;
  position: relative;
}

.faq summary:before {
  content: "";
  border-radius: var(--radius-main);
  width: 18px;
  height: 11px;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.63031 0.666706C9.3965 0.665355 9.16473 0.71017 8.94828 0.798579C8.73183 0.886989 8.53495 1.01725 8.36895 1.18191L1.26271 8.28815C1.09706 8.4538 0.965667 8.65045 0.876021 8.86687C0.786375 9.08329 0.740234 9.31526 0.740234 9.54951C0.740234 9.78377 0.786375 10.0157 0.876021 10.2322C0.965667 10.4486 1.09706 10.6452 1.26271 10.8109C1.42835 10.9765 1.625 11.1079 1.84142 11.1976C2.05785 11.2872 2.28981 11.3333 2.52407 11.3333C2.75832 11.3333 2.99028 11.2872 3.20671 11.1976C3.42313 11.1079 3.61978 10.9765 3.78542 10.8109L9.63031 4.93045L15.493 10.5799C15.6564 10.7616 15.8556 10.9077 16.078 11.0089C16.3005 11.1102 16.5414 11.1644 16.7858 11.1683C17.0301 11.1722 17.2727 11.1256 17.4982 11.0314C17.7238 10.9373 17.9275 10.7976 18.0966 10.6212C18.2657 10.4447 18.3966 10.2353 18.481 10.0059C18.5655 9.77657 18.6017 9.53227 18.5875 9.28828C18.5732 9.0443 18.5087 8.80588 18.3981 8.58794C18.2875 8.37 18.1331 8.17722 17.9446 8.02167L10.8384 1.16414C10.5135 0.850908 10.0816 0.673059 9.63031 0.666706Z' fill='%23532800'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 10px;
  position: absolute;
  top: 45%;
  right: 15px;
  overflow: hidden;
  rotate: 180deg;
}

.faq summary + div {
  border-style: solid;
  border-width: 0 1px 1px;
  border-color: var(--color-gray-400);
  padding: 12px 24px;
}

.faq p:not(:last-child) {
  margin-bottom: 15px;
}

.faq h1, .faq h2 {
  text-align: center;
  margin: 0 auto 28px;
}

.faq a {
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .faq {
    padding: 30px 0;
  }

  .faq summary {
    line-height: 1.2;
  }

  .faq br {
    display: none;
  }
}

.contact__wrapper {
  grid-template-columns: 1fr 550px;
  gap: 1.875rem;
  display: grid;
  position: relative;
}

.contact__text > :not(:last-child) {
  margin-bottom: 1.875rem;
}

.contact__box {
  flex-direction: column;
  display: flex;
}

.contact__box span {
  font-weight: var(--fw-600);
}

.contact__box p, .contact__box a {
  font-weight: var(--fw-500);
  color: var(--color-gray-400);
}

.contact__box a:hover, .contact__box a:focus {
  color: var(--color-orange-400);
}

.contact__form {
  border-radius: var(--radius-main);
  background-color: var(--color-gray-100);
  padding: 30px;
}

.contact__form .login input {
  border: 1px solid var(--color-gray-400);
  border-radius: var(--radius-main);
  width: 100%;
  margin-bottom: 30px;
  padding: 10px 15px;
  display: block;
  position: relative;
  overflow: hidden;
}

.contact__form label:not(.login) {
  border: 1px solid var(--color-gray-400);
  border-radius: var(--radius-main);
  width: 100%;
  margin-bottom: 30px;
  padding: 10px 15px;
  display: block;
  position: relative;
}

.contact__form label:not(.login):not(:nth-child(n+4)):before {
  content: "";
  width: 10px;
  height: 8px;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 20px;
  translate: 0 -50%;
}

.contact__form label:not(.login) > * {
  width: 100%;
}

.contact__form select {
  background-color: inherit;
  position: relative;
}

.contact__form .btn {
  max-width: 100%;
}

.contact__form.hide {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.contact .contact-icon:after {
  filter: invert();
  background-color: #ffffffe6;
}

.contact .show-login {
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: .3s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.contact .show-login.show-message {
  opacity: 1;
  visibility: visible;
  position: relative;
}

.contact--login h1 {
  margin-bottom: 30px;
  font-size: clamp(1.75rem, 1.6rem + .75vi, 2.5rem);
}

.contact--login h2 {
  font-size: clamp(1.2rem, 1rem + .2vi, 1.3rem);
  line-height: 1.6;
}

.contact--login .container {
  max-width: 800px;
}

@media only screen and (max-width: 1180px) {
  .contact__wrapper {
    grid-template-columns: 400px 1fr;
  }
}

@media only screen and (max-width: 992px) {
  .contact__wrapper {
    grid-template-columns: 300px 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .contact__wrapper {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .contact__form {
    padding: 15px;
  }
}

.contact-icon {
  padding-left: 65px;
  position: relative;
}

.contact-icon:before, .contact-icon:after {
  content: "";
  position: absolute;
}

.contact-icon:before {
  z-index: 1;
  width: 26px;
  height: 26px;
  filter: var(--orange-filter);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: 11px;
  left: 12px;
}

.contact-icon:after {
  width: 50px;
  height: 50px;
  background-color: #ffffff0d;
  border-radius: 50%;
  top: 0;
  left: 0;
}

.contact-icon--time:before {
  background-image: url("../images/icons/clock.svg");
}

.contact-icon--email:before {
  background-image: url("../images/icons/mail.svg");
}

.contact-icon--phone:before {
  background-image: url("../images/icons/phone.svg");
}

.contact-icon--pin:before {
  height: 32px;
  background-image: url("../images/icons/pin.svg");
}

.tabs {
  top: calc(var(--header-height)  + 30px);
  height: max-content;
  background-color: var(--color-white);
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 1.25rem;
  display: grid;
  position: sticky;
}

.hide ~ .main .tabs {
  top: 0;
}

@media only screen and (max-width: 992px) {
  .tabs {
    top: var(--header-height);
    width: 100%;
    scrollbar-color: var(--color-orange-400) var(--color-gray-100);
    scrollbar-width: thin;
    padding: 20px 0;
    display: flex;
    overflow-x: scroll;
  }

  .tabs__item, .tabs .btn {
    width: 100%;
    height: 100%;
    white-space: nowrap;
  }
}

.pane {
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: 1.25rem;
  display: grid;
}

.pane:not(:first-child) {
  padding-top: 3.125rem;
}

.pane__table {
  overflow-x: auto;
}

.pane__table table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 500px;
}

.pane__table td {
  border: 1px solid var(--color-orange-400);
  padding: 10px;
}

.pane strong, .pane b {
  font-weight: var(--fw-700);
}

.pane--reciept img {
  border-radius: 0;
}

.dialog {
  border-color: var(--color-orange-400);
  width: 100%;
  height: max-content;
  max-width: 500px;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  padding: 20px 20px 60px;
  transition-property: opacity, visibility;
  transition-duration: .2s;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

.dialog__content {
  height: auto;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dialog__content:before {
  content: "";
  border-radius: var(--radius-main);
  width: 70px;
  height: 70px;
  background-image: url("data:image/svg+xml,%3Csvg width='49' height='37' viewBox='0 0 49 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.4787 27.995C16.3072 29.1666 14.4077 29.1666 13.2361 27.995L5.20117 19.9601C4.07934 18.8383 2.2605 18.8383 1.13867 19.9601C0.0168439 21.0819 0.0168433 22.9008 1.13867 24.0226L13.2361 36.12C14.4077 37.2916 16.3072 37.2916 17.4787 36.12L48.1476 5.45117C49.2694 4.32934 49.2694 2.5105 48.1476 1.38867C47.0258 0.266844 45.2069 0.266843 44.0851 1.38867L17.4787 27.995Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 46px;
  background-color: var(--color-orange-400);
  margin-bottom: 30px;
}

.dialog__close {
  width: 30px;
  height: 30px;
  background-color: #0000;
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.2053 0.347656L12.5004 10.0525L2.79557 0.347656L0.347656 2.79557L10.0525 12.5004L0.347656 22.2053L2.79557 24.6532L12.5004 14.9483L22.2053 24.6532L24.6532 22.2053L14.9483 12.5004L24.6532 2.79557L22.2053 0.347656Z' fill='%23000'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  position: relative;
}

.dialog[open] {
  opacity: 1;
  visibility: visible;
}

.hero {
  min-height: 650px;
  color: var(--color-white);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  display: flex;
}

.hero__content {
  width: 100%;
  max-width: 900px;
  position: relative;
}

.hero__content h1, .hero__content p {
  margin-bottom: 32px;
}

@media only screen and (max-width: 768px) {
  .hero {
    min-height: 500px;
  }
}

.about__inner {
  grid-template-columns: 590px 1fr;
  align-items: center;
  gap: 1.875rem;
  display: grid;
}

.about__content {
  text-align: justify;
  z-index: 1;
  position: relative;
}

.about__content > :not(:last-child) {
  margin-bottom: 1.875rem;
}

.about__picture {
  justify-self: end;
  position: relative;
}

.about__picture.lightning:before {
  translate: -55%;
}

@media only screen and (max-width: 1280px) {
  .about__picture.lightning:before {
    translate: -35% -15%;
  }
}

@media only screen and (max-width: 992px) {
  .about__picture.lightning:before {
    display: none;
  }
}

.about--page .about__inner {
  align-items: start;
  margin-bottom: 2.5rem;
}

.about--page .about__picture, .about--page .about__picture img {
  height: 100%;
  width: 100%;
}

.about--page .about__docs > :not(:last-child) {
  margin-bottom: 1.875rem;
}

.about--page .about__docs-box {
  font-weight: var(--fw-600);
  color: var(--color-white);
  background-color: var(--color-orange-400);
  padding: 20px;
}

.about--page .about__docs-box p:not(:last-child) {
  margin-bottom: 20px;
}

.about--page h1 {
  text-align: left;
}

@media only screen and (max-width: 1180px) {
  .about__inner {
    grid-template-columns: 400px 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .about__inner {
    grid-template-columns: 1fr;
  }

  .about__picture {
    margin: 0 auto;
  }
}

.info-block {
  border-radius: var(--radius-main);
  max-width: 1280px;
  color: var(--color-white);
  background-color: var(--color-orange-400);
  margin: 0 auto;
  padding: 30px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  translate: 0 -80%;
}

.info-block__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5625rem;
  display: grid;
}

.info-block__list h3 {
  font-weight: var(--fw-600);
  margin-bottom: 15px;
  font-size: 24px;
}

.info-block h2 {
  margin-bottom: 15px;
  font-size: 32px;
}

@media only screen and (max-width: 992px) {
  .info-block {
    translate: 0 -50%;
  }
}

@media only screen and (max-width: 768px) {
  .info-block {
    translate: 0 -20%;
  }

  .info-block__list {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 475px) {
  .info-block {
    translate: 0 -10%;
  }
}

.client__list {
  display: flex;
}

.client__item {
  counter-increment: list;
  border: 1px solid var(--color-orange-400);
  border-radius: var(--radius-main);
  width: 100px;
  transition: width .5s;
  display: flex;
  overflow: hidden;
}

.client__item:not(:last-child) {
  margin-right: 20px;
}

.client__item.active {
  width: 100%;
}

.client__item.active h3 {
  color: var(--color-white);
  background-color: var(--color-orange-400);
}

.client__item.active h3:after {
  -webkit-text-stroke-color: var(--color-white);
}

.client__item.active h3:hover, .client__item.active h3:focus {
  color: var(--color-white);
}

.client__item.active p {
  height: max-content;
}

.client__content {
  --height-item: 520px;
  width: 0;
  max-height: var(--height-item);
  background-color: var(--color-white);
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2.5rem 1.875rem;
  transition: width .5s, opacity .2s;
  display: flex;
  position: relative;
}

.client__content:before {
  content: "";
  width: 450px;
  height: 380px;
  opacity: .3;
  visibility: hidden;
  background-image: url("../images/home/about-bg.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -20px;
  right: 0;
}

.client__content p {
  height: var(--height-item);
  font-weight: var(--fw-600);
  font-size: 24px;
  position: relative;
}

.client__content p:before {
  content: counter(list, decimal-leading-zero);
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.active .client__content {
  width: 100%;
  opacity: 1;
}

.active .client__content:before {
  visibility: visible;
}

.client h3 {
  font-weight: var(--fw-600);
  text-transform: capitalize;
  writing-mode: vertical-rl;
  background-color: var(--color-white);
  cursor: pointer;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 30px 20px;
  transition: color .3s;
  display: flex;
  position: relative;
  rotate: 180deg;
}

.client h3:after {
  content: counter(list, decimal-leading-zero);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--color-orange-400);
  color: var(--color-white);
  align-items: center;
  margin-top: 20px;
  font-size: 28px;
  display: flex;
}

.client h3:hover, .client h3:focus {
  color: var(--color-orange-400);
}

.client div > :not(:last-child) {
  margin-bottom: 1.875rem;
}

@media only screen and (max-width: 992px) {
  .client p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .client__list {
    flex-direction: column;
  }

  .client__item {
    width: 100%;
    height: 90px;
    flex-direction: column;
  }

  .client__item:not(:last-child) {
    margin-bottom: 30px;
  }

  .client__item.active {
    height: 100%;
  }

  .client__content {
    transition: height .5s, opacity .2s;
  }

  .client h3 {
    width: 100%;
    writing-mode: horizontal-tb;
    align-items: center;
    rotate: none;
  }

  .client h3:after {
    margin: 0;
  }
}

.services {
  background-color: var(--color-gray-100);
}

.services__grid {
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 1.5625rem;
  display: grid;
}

.services__item {
  border-radius: calc(var(--radius-main) * 2);
  height: 100%;
  background-color: var(--color-white);
  flex-direction: column;
  padding: 1.875rem 25px;
  display: flex;
  position: relative;
}

.services__item:before {
  content: "";
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: .06;
  background-image: url("../images/icons/lightning.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.services__item > * {
  z-index: 1;
  position: relative;
}

.services__item > :not(:last-child) {
  margin-bottom: 20px;
}

.services .btn {
  max-width: none;
  margin-top: auto;
  padding-block: 10px;
}

.services h2 {
  max-width: 800px;
  text-align: center;
  margin: 0 auto 1.875rem;
}

.services h2 + p {
  width: max-content;
  text-align: center;
  margin: 0 auto 1.875rem;
  display: block;
}

.services--law {
  background-color: #0000;
}

.services--law .services__grid {
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

.services--law .services__item {
  font-weight: var(--fw-600);
  color: var(--color-white);
  background-color: var(--color-orange-400);
}

.services--law .services__item:before {
  opacity: .1;
  transition: opacity .2s;
}

.services--law .services__item:focus:before, .services--law .services__item:hover:before {
  opacity: .2;
}

.services--law .services__item:focus a, .services--law .services__item:hover a {
  color: var(--color-white);
  text-decoration: underline;
}

.services--law a {
  height: 100%;
  align-items: center;
  display: flex;
  position: static;
}

.services--law a:before {
  content: "";
  position: absolute;
  inset: 0;
}

.services--law h1 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 576px) {
  .services h2 + p {
    width: auto;
  }

  .services__grid {
    grid-template-columns: 1fr;
  }
}

.faq-home {
  position: relative;
}

.faq-home__inner {
  grid-template-columns: 600px 1fr;
  gap: 3.125rem;
  display: grid;
}

.faq-home__picture {
  height: max-content;
  position: sticky;
  top: 80px;
}

.faq-home__picture img {
  width: 100%;
}

.faq-home h2 {
  text-align: center;
  margin: 0 auto 1.875rem;
}

.faq-home img {
  height: auto;
}

@media only screen and (max-width: 1180px) {
  .faq-home__inner {
    grid-template-columns: 400px 1fr;
  }
}

@media only screen and (max-width: 992px) {
  .faq-home__inner {
    grid-template-columns: 1fr;
  }

  .faq-home__picture {
    width: 100%;
    margin-bottom: 60px;
    position: relative;
  }

  .faq-home__picture img {
    aspect-ratio: 16 / 9;
  }
}

.plans__header {
  margin-bottom: 3.125rem;
}

.plans__header > :not(:last-child) {
  margin-bottom: 20px;
}

.plans__list {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 1.875rem;
  display: grid;
}

.plans__item {
  height: max-content;
  background-color: var(--color-gray-100);
  flex-direction: column;
  padding: 30px;
  display: flex;
}

.plans__item > :not(:last-child) {
  margin-bottom: 20px;
  display: block;
}

.plans__item p {
  background-color: var(--color-white);
  padding: 15px;
  line-height: 1.6;
}

.plans__item--third {
  grid-column: span 2;
}

.plans__price {
  font-weight: var(--fw-700);
  color: var(--color-orange-400);
  font-size: clamp(1.25rem, 1.2rem + .5vi, 2rem);
}

.plans > :not(:last-child) {
  margin-bottom: 2.5rem;
}

@media only screen and (max-width: 992px) {
  .plans__item {
    padding: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .plans__list {
    grid-template-columns: 1fr;
  }

  .plans__item--third {
    grid-column: auto;
  }
}

.change__grid {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.change__grid .doc-link {
  justify-content: flex-end;
  gap: 1.875rem;
  display: flex;
}

.change__grid a {
  font-weight: var(--fw-600);
  font-size: 20px;
}

.change__item {
  border: 1px solid var(--color-orange-400);
  border-radius: calc(var(--radius-main) * 2);
  height: 100%;
  background-color: var(--color-white);
  flex-direction: column;
  padding: 1.875rem 25px;
  display: flex;
  position: relative;
}

.change__item:before {
  content: "";
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: .06;
  background-image: url("../images/icons/lightning.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.change ul {
  padding-left: 30px;
}

.change li {
  position: relative;
}

.change li:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: var(--color-orange-400);
  position: absolute;
  top: 0;
  left: -32px;
  transform: skew(-16deg, -5deg);
  -webkit-mask-image: url("../images/icons/lightning.svg");
  mask-image: url("../images/icons/lightning.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.change li:not(:last-child) {
  margin-bottom: 20px;
}

.change li ul li:first-child {
  margin-top: 20px;
}

.change > :not(:last-child) {
  margin-bottom: 2.5rem;
}

.danger {
  text-align: justify;
}

.danger h2 {
  font-size: 26px;
}

.danger ul {
  padding-left: 30px;
}

.danger li {
  position: relative;
}

.danger li:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: var(--color-orange-400);
  position: absolute;
  top: 0;
  left: -32px;
  transform: skew(-16deg, -5deg);
  -webkit-mask-image: url("../images/icons/lightning.svg");
  mask-image: url("../images/icons/lightning.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.danger a {
  color: var(--color-orange-400);
}

.danger a:hover, .danger a:focus {
  text-decoration: underline;
}

.danger > * {
  z-index: 1;
  position: relative;
}

.danger > :not(:last-child), .danger--disputes h2, .danger--disputes p, .danger--disputes ul {
  margin-bottom: 30px;
}

.danger--call .call-center {
  grid-template-columns: 1fr;
  gap: 2.5rem;
  display: grid;
}

.danger--call h2, .danger--call h3 {
  margin-bottom: 30px;
}

.danger--call p, .danger--call ul {
  margin-bottom: 15px;
}

@media only screen and (max-width: 992px) {
  .danger .call-center {
    grid-template-columns: 1fr;
  }
}

.price__table {
  border: 1px solid var(--color-orange-400);
  overflow-x: scroll;
}

.price__table thead {
  font-weight: var(--fw-500);
  color: var(--color-white);
  background-color: var(--color-orange-400);
}

.price__table td, .price__table th {
  width: 13%;
  padding: 18px 30px;
}

.price__table td p, .price__table th p {
  text-align: center;
}

.price__table tbody {
  font-weight: var(--fw-600);
}

.price__table tbody tr {
  border-top: 1px solid var(--color-orange-400);
}

.price__table tbody tr:nth-child(2n) {
  color: var(--color-white);
  background-color: var(--color-orange-400);
}

.price__table-body {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  min-width: 980px;
  margin: 0 auto;
}

.price__formula {
  text-align: center;
  margin: 20px 0;
}

.price__text p:not(:last-child) {
  margin-bottom: 20px;
}

.price > :not(:last-child) {
  margin-bottom: 2.1875rem;
}

.price h1, .price .slogan {
  margin-bottom: 1.875rem;
}

.price .text-price {
  font-weight: var(--fw-700);
  text-align: center;
}

.change__table {
  overflow-x: auto;
}

.change__table td {
  border: 1px solid var(--color-orange-400);
  text-align: center;
  padding: 3px;
}

.change__table-body {
  border-collapse: collapse;
  width: 100%;
  min-width: 1200px;
  text-align: left;
  margin: 0 auto;
  display: table;
  overflow-x: scroll;
}

.help__tabs {
  grid-template-columns: 350px 1fr;
  gap: 1.875rem;
  display: grid;
}

.help h1 {
  margin-bottom: 2.5rem;
}

.help a {
  color: var(--color-orange-400);
}

.help a:hover, .help a:focus {
  text-decoration: underline;
}

@media only screen and (max-width: 1180px) {
  .help__tabs {
    grid-template-columns: 270px 1fr;
  }
}

@media only screen and (max-width: 992px) {
  .help__tabs {
    grid-template-columns: 1fr;
  }

  .help h1 {
    margin-bottom: 20px;
  }
}

/*# sourceMappingURL=main.css.map */
