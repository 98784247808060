@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact-icon {
  position: relative;
  padding-left: 65px;

  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::before {
    left: 12px;
    top: 11px;
    z-index: 1;
    width: 26px;
    height: 26px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: var(--orange-filter);
  }

  &::after {
    left: 0;
    top: 0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.05);
  }

  &--time {
    &::before {
      background-image: url("../images/icons/clock.svg");
    }
  }

  &--email {
    &::before {
      background-image: url("../images/icons/mail.svg");
    }
  }

  &--phone {
    &::before {
      background-image: url("../images/icons/phone.svg");
    }
  }

  &--pin {
    &::before {
      height: 32px;
      background-image: url("../images/icons/pin.svg");
    }
  }
}