@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0 0 15px var(--color-gray-400);
  background-color: var(--color-white);
  transition-property: transform, padding;
  transition-duration: 0.15s;

  &__top {
    padding: 15px 0;
    color: var(--color-white);
    background-color: var(--color-dark-400);
  }

  &__inner {
    display: flex;
    align-items: center;
    font-size: 15px;

    .logo {
      margin-right: auto;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: rem(30);
    }

    span {
      &:first-child {
        margin-bottom: 5px;
      }
    }

    &--phone {
      flex-direction: row;

      a:not(:last-child) {
        margin-right: 20px;

        @include media(desktop) {
          margin-right: 10px;
        }

        @include media(tablet-wide) {
          margin-right: 0;
        }
      }
    }
  }

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }

  &.hide.scroll {
    transform: translateY(0);
  }

  @include media(tablet-wide) {
    display: flex;

    &__top {
      flex-shrink: 0;
      padding-right: 50px;
      width: 100%;
    }
  }

  @include media(tablet-wide) {
    &__contact--phone {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  @include media(tablet) {
    &__contact {
      display: none;
    }
  }
}