@mixin cover-background {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin contain-background {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin cover-mask {
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: cover;
}

@mixin contain-mask {
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}