@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;

  &__inner {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @include media(tablet-wide) {
      position: fixed;
      right: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 1.5);
      max-width: 350px;
      height: calc(var(--vh));
      background-color: var(--color-white);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }
  }

  &__list {
    display: flex;
    margin-right: auto;

    .header & {
      @include media(tablet-wide) {
        z-index: -1;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 30px;
      }
    }
  }

  &__item {
    position: relative;

    &:not(:last-of-type) {
      margin-right: 13px;

      @include media(tablet-wide) {
        margin-right: 0;
      }
    }

    @include media(tablet-wide) {
      &:not(.nav__dropdown li) {
        padding: 0;
        margin-bottom: 20px;
      }
    }

    &--dropdown {
      padding-right: 5px;

      span {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: -5px;
          width: 10px;
          height: 8px;
          background-image: var(--arrow);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          translate: 0 -50%;
          transition: rotate 0.2s, filter 0.2s;

          @include media(tablet-wide) {
            right: -15px;
          }
        }
      }

      &.open {

        span::before {
          rotate: 180deg;
          filter: var(--orange-filter);
        }

        span {
          color: var(--color-orange-400);
        }

        #{$root}__dropdown {
          opacity: 1;
          visibility: visible;

          @include media(tablet-wide) {
            display: block;
          }
        }
      }

      &:hover,
      &:focus {
        &::before {
          rotate: 180deg;
          filter: var(--orange-filter);
        }
      }

      @include media(tablet-wide) {
        text-align: center;
      }
    }
  }

  &__link {
    display: inline-flex;
    padding: 0 10px 0 0;
    font-size: 18px;
    letter-spacing: 0.035em;
    white-space: nowrap;
    color: var(--color-dark-450);
    height: max-content;
    background-color: transparent;
    cursor: pointer;
    transition-property: color;
    transition-duration: 0.2s;

    &:hover,
    &:focus {
      color: var(--color-orange-400);
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    &.true {
      color: var(--color-orange-400);
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    @include media(desktop) {
      font-size: 16px;
    }

    @include media(tablet-wide) {
      padding: 0;
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    border: 1px solid var(--color-orange-400);
    background-color: var(--color-white);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;

    #{$root}__link {
      padding: 10px;
    }

    @include media(tablet-wide) {
      display: none;
      margin: 0 auto;
      position: relative;
      top: 0;
    }
  }
}