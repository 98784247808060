@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.tabs {
  position: sticky;
  top: calc(var(--header-height) + 30px);
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  height: max-content;
  background-color: var(--color-white);
  gap: rem(20);

  .hide~.main & {
    top: 0;
  }

  @include media(tablet) {
    top: var(--header-height);
    width: 100%;
    display: flex;
    overflow-x: scroll;
    padding: 20px 0;
    scrollbar-color: var(--color-orange-400) var(--color-gray-100);
    scrollbar-width: thin;

    &__item,
    .btn {
      width: 100%;
      height: 100%;
      white-space: nowrap;
    }
  }
}