/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

:root {
  --content-width: 1280px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "ArtegraSans", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-900: 900;

  // color
  --color-white: #fff;
  --color-black: #000;

  --color-dark-400: #15141c;
  --color-orange-400: #ff7617;

  --color-gray-100: #f7f7f7;
  --color-gray-400: #616161;

  //filter
  --orange-filter: brightness(0) saturate(100%) invert(76%) sepia(58%) saturate(6927%) hue-rotate(350deg) brightness(105%) contrast(101%);

  //  radius
  --radius-main: 8px;

  // shadow

  // gradient
  --gradient: linear-gradient(89.88deg, rgba(0, 0, 0, 0.528) 59.48%, rgba(0, 0, 0, 0.304) 78.99%, rgba(0, 0, 0, 0) 99.89%);

  // icons
  --arrow: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.390524 0.723858C-0.130175 1.24456 -0.130175 2.08878 0.390524 2.60948L11.0572 13.2761C11.5779 13.7968 12.4221 13.7968 12.9428 13.2761L23.6095 2.60948C24.1302 2.08878 24.1302 1.24456 23.6095 0.723858C23.0888 0.203159 22.2446 0.203159 21.7239 0.723858L12 10.4477L2.27614 0.723858C1.75544 0.203159 0.911223 0.203159 0.390524 0.723858Z' fill='%23000'/%3E%3C/svg%3E%0A");
  ;
}