@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.pane {
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  gap: rem(20);

  &:not(:first-child) {
    padding-top: rem(50);
  }

  &__table {
    overflow-x: auto;

    table {
      border-collapse: collapse;
      border-spacing: 0;
      min-width: 500px;
    }

    td {
      border: 1px solid var(--color-orange-400);
      padding: 10px;
    }
  }

  strong,
  b {
    font-weight: var(--fw-700);
  }

  &--reciept {
    img {
      border-radius: 0;
    }
  }
}