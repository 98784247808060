@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(16px, 1vw, 20px);
  line-height: 1.2;
  letter-spacing: 0;
  color: var(--color-dark-400);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);

    &.overflow::before {
      z-index: 10;
    }
  }

  &.open {
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.main {
  padding-top: var(--header-height);
}

.logo {
  display: block;
  margin-right: 20px;
  width: 100%;
  max-width: 200px;

  img {
    width: 100%;
  }

  @include media(tablet) {
    max-width: 160px;
  }
}

img {
  overflow: hidden;
  border-radius: var(--radius-main);
}

section {
  padding: rem(80) 0;

  @include media(tablet) {
    padding: rem(40) 0;
  }
}

.overflow {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-color: var(--color-black);
    opacity: 0.6;
    inset: 0;
  }
}

.slogan {
  position: relative;
  display: inline-block;
  width: fit-content;
  font-weight: var(--fw-500);
  font-size: 18px;
  color: var(--color-orange-400);
  margin-block-end: 15px;
  padding-inline: 30px;

  &::before,
  &::after {
    @include contain-mask;

    content: "";
    position: absolute;
    top: 50%;
    mask-image: url("../images/icons/lightning.svg");
    width: 25px;
    height: 25px;
    background-color: currentColor;
    transform: skew(-16deg, -5deg);
    translate: 0 -50%;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  @include media(mobile-m) {
    width: auto;
    text-align: center;
  }
}

.lightning {
  position: relative;

  &::before {
    @include cover-mask;

    content: "";
    position: absolute;
    mask-image: url("../images/icons/lightning.svg");
    width: 100%;
    height: 100%;
    max-width: 320px;
    background-color: var(--color-orange-400);
  }
}

.background-image {
  position: relative;

  &::before {
    @include cover-background;

    content: "";
    position: absolute;
    z-index: -1;
    background-image: url("../images/home/about-bg.png");
    background-attachment: fixed;
    opacity: 0.05;
    inset: 0;
  }
}

.selected {
  position: relative;
  overflow: hidden;
  font-weight: 600;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange-400);
  padding-block: rem(40);
  padding-inline: rem(40) rem(90);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    mask-image: url("../images/icons/lightning.svg");
    mask-position: right center;
    mask-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    transform: skew(-16deg, -5deg);
    translate: -50px -15%;
  }

  @include media(tablet) {
    padding: 20px;
    padding-right: 80px;

    &::before {
      translate: 30px -15%;
    }
  }

  @include media(mobile-m) {
    &::before {
      translate: 90px -54%;
    }
  }
}

.error-message {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 10px;
  bottom: -27px;
  color: red;

  .error & {
    opacity: 1;
    visibility: visible;
  }
}