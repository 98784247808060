@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.client {
  $root: &;

  &__list {
    display: flex;
  }

  &__item {
    counter-increment: list;
    display: flex;
    overflow: hidden;
    border: 1px solid var(--color-orange-400);
    border-radius: var(--radius-main);
    width: 100px;
    transition: width 0.5s;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &.active {
      width: 100%;

      h3 {
        color: var(--color-white);
        background-color: var(--color-orange-400);

        &::after {
          -webkit-text-stroke-color: var(--color-white);
        }

        &:hover,
        &:focus {
          color: var(--color-white);
        }
      }

      p {
        height: max-content;
      }
    }
  }

  &__content {
    --height-item: 520px;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: rem(40) rem(30);
    width: 0;
    max-height: var(--height-item);
    background-color: var(--color-white);
    opacity: 0;
    transition: width 0.5s, opacity 0.2s;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: -20px;
      width: 450px;
      height: 380px;
      background-image: url("../images/home/about-bg.png");
      background-position: bottom right;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.3;
      visibility: hidden;
    }

    p {
      position: relative;
      height: var(--height-item);
      font-weight: var(--fw-600);
      font-size: 24px;

      &::before {
        content: counter(list, decimal-leading-zero);
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
    }

    .active & {
      width: 100%;
      opacity: 1;

      &::before {
        visibility: visible;
      }
    }
  }

  h3 {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 30px 20px;
    font-weight: var(--fw-600);
    text-transform: capitalize;
    writing-mode: vertical-rl;
    background-color: var(--color-white);
    transition: color 0.3s;
    cursor: pointer;
    rotate: 180deg;

    &::after {
      content: counter(list, decimal-leading-zero);
      display: flex;
      align-items: center;
      margin-top: 20px;
      font-size: 28px;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: var(--color-orange-400);
      color: var(--color-white);
    }

    &:hover,
    &:focus {
      color: var(--color-orange-400);
    }
  }

  div>*:not(:last-child) {
    margin-bottom: rem(30);
  }

  @include media(tablet) {
    p {
      font-size: 20px;
    }
  }

  @include media(mobile-l) {
    &__list {
      flex-direction: column;
    }

    &__item {
      flex-direction: column;
      width: 100%;
      height: 90px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &.active {
        height: 100%;
      }
    }

    &__content {
      transition: height 0.5s, opacity 0.2s;
    }

    h3 {
      align-items: center;
      width: 100%;
      writing-mode: horizontal-tb;
      rotate: 0deg;

      &::after {
        margin: 0;
      }
    }
  }
}