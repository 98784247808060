@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  border-color: var(--color-orange-400);
  padding: 20px;
  padding-bottom: 60px;
  width: 100%;
  height: max-content;
  max-width: 500px;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.2s;
  translate: -50% -50%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    text-align: center;

    &::before {
      content: "";
      margin-bottom: 30px;
      border-radius: var(--radius-main);
      width: 70px;
      height: 70px;
      background-image: url("data:image/svg+xml,%3Csvg width='49' height='37' viewBox='0 0 49 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.4787 27.995C16.3072 29.1666 14.4077 29.1666 13.2361 27.995L5.20117 19.9601C4.07934 18.8383 2.2605 18.8383 1.13867 19.9601C0.0168439 21.0819 0.0168433 22.9008 1.13867 24.0226L13.2361 36.12C14.4077 37.2916 16.3072 37.2916 17.4787 36.12L48.1476 5.45117C49.2694 4.32934 49.2694 2.5105 48.1476 1.38867C47.0258 0.266844 45.2069 0.266843 44.0851 1.38867L17.4787 27.995Z' fill='white'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-size: 46px;
      background-repeat: no-repeat;
      background-color: var(--color-orange-400);
    }
  }

  &__close {
    position: relative;
    margin-left: auto;
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.2053 0.347656L12.5004 10.0525L2.79557 0.347656L0.347656 2.79557L10.0525 12.5004L0.347656 22.2053L2.79557 24.6532L12.5004 14.9483L22.2053 24.6532L24.6532 22.2053L14.9483 12.5004L24.6532 2.79557L22.2053 0.347656Z' fill='%23000'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  &[open] {
    opacity: 1;
    visibility: visible;
  }
}