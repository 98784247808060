@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding: rem(30) 0;
  color: var(--color-white);
  background-color: var(--color-dark-400);

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(30);
  }

  &__bottom {
    font-size: 14px;
    text-align: center;
  }

  .logo {
    max-width: 230px;
  }

  @include media(tablet) {
    &__top {
      flex-direction: column;
      align-items: center;
    }
  }

  @include media(tablet) {
    .nav__list {
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }
  }
}