/* stylelint-disable declaration-no-important */
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: var(--radius-main);
  padding: 18px 10px;
  width: 100%;
  max-width: 220px;
  font-weight: var(--fw-600);
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-orange-400);
  transition-property: background-color, color, border-color;
  transition-duration: 0.2s;

  &:hover,
  &:focus {
    border-color: var(--color-orange-400);
    color: var(--color-orange-400);
    background-color: var(--color-white);
  }

  &--reverse {
    border-color: var(--color-orange-400);
    color: var(--color-orange-400);
    background-color: var(--color-white);

    &:hover,
    &:focus {
      border-color: var(--color-orange-400);
      color: var(--color-white);
      background-color: var(--color-orange-400);
    }
  }

  &--tab {
    width: 100%;
    max-width: 100%;
  }
}