@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.faq-home {
  position: relative;

  &__inner {
    display: grid;
    grid-template-columns: 600px 1fr;
    gap: rem(50);
  }

  &__picture {
    position: sticky;
    top: 80px;
    height: max-content;

    img {
      width: 100%;
    }
  }

  h2 {
    margin: 0 auto rem(30);
    text-align: center;
  }

  img {
    height: auto;
  }

  @include media(tablet-wide) {
    &__inner {
      grid-template-columns: 400px 1fr;
    }
  }

  @include media(tablet) {
    &__inner {
      grid-template-columns: 1fr;
    }

    &__picture {
      position: relative;
      margin-bottom: 60px;
      width: 100%;

      img {
        aspect-ratio: 16 / 9;
      }
    }
  }
}