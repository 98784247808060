@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.faq {
  &__item {
    background-color: var(--color-white);

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  details {
    overflow: hidden;

    p {
      font-weight: var(--fw-500);
      font-size: 20px;
    }

    &[open] {
      summary {
        border-color: var(--color-orange-400);
        color: var(--color-white);
        background-color: var(--color-orange-400);

        &::before {
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(58deg) brightness(102%) contrast(101%);
          rotate: unset;
        }
      }
    }
  }

  summary {
    position: relative;
    border-style: solid;
    border-width: 1px 1px 0;
    border-color: var(--color-gray-100);
    padding: 16px 50px 16px 24px;
    font-family: var(--font-family-primary);
    font-weight: var(--fw-600);
    font-size: 20px;
    line-height: 1.2;
    background-color: var(--color-gray-100);
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      right: 15px;
      top: 45%;
      overflow: hidden;
      border-radius: var(--radius-main);
      width: 18px;
      height: 11px;
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.63031 0.666706C9.3965 0.665355 9.16473 0.71017 8.94828 0.798579C8.73183 0.886989 8.53495 1.01725 8.36895 1.18191L1.26271 8.28815C1.09706 8.4538 0.965667 8.65045 0.876021 8.86687C0.786375 9.08329 0.740234 9.31526 0.740234 9.54951C0.740234 9.78377 0.786375 10.0157 0.876021 10.2322C0.965667 10.4486 1.09706 10.6452 1.26271 10.8109C1.42835 10.9765 1.625 11.1079 1.84142 11.1976C2.05785 11.2872 2.28981 11.3333 2.52407 11.3333C2.75832 11.3333 2.99028 11.2872 3.20671 11.1976C3.42313 11.1079 3.61978 10.9765 3.78542 10.8109L9.63031 4.93045L15.493 10.5799C15.6564 10.7616 15.8556 10.9077 16.078 11.0089C16.3005 11.1102 16.5414 11.1644 16.7858 11.1683C17.0301 11.1722 17.2727 11.1256 17.4982 11.0314C17.7238 10.9373 17.9275 10.7976 18.0966 10.6212C18.2657 10.4447 18.3966 10.2353 18.481 10.0059C18.5655 9.77657 18.6017 9.53227 18.5875 9.28828C18.5732 9.0443 18.5087 8.80588 18.3981 8.58794C18.2875 8.37 18.1331 8.17722 17.9446 8.02167L10.8384 1.16414C10.5135 0.850908 10.0816 0.673059 9.63031 0.666706Z' fill='%23532800'/%3E%3C/svg%3E");
      background-position: center;
      background-size: 18px 10px;
      background-repeat: no-repeat;
      rotate: 180deg;
    }

    +div {
      border-style: solid;
      border-width: 0 1px 1px;
      border-color: var(--color-gray-400);
      padding: 12px 24px;
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  h1,
  h2 {
    margin: 0 auto 28px;
    text-align: center;
  }

  a {
    margin: 0 auto;
  }

  @include media(mobile-l) {
    padding: 30px 0;

    summary {
      line-height: 1.2;
    }

    br {
      display: none;
    }
  }
}