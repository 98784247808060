@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.hero {
  @include flex-v-center;
  @include cover-background;

  min-height: 650px;
  color: var(--color-white);

  &__content {
    position: relative;
    width: 100%;
    max-width: 900px;

    h1,
    p {
      margin-bottom: 32px;
    }
  }

  @include media(mobile-l) {
    min-height: 500px;
  }
}