@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.services {
  $root: &;

  background-color: var(--color-gray-100);

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: rem(25);
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: calc(var(--radius-main) * 2);
    padding: rem(30) 25px;
    height: 100%;
    background-color: var(--color-white);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-image: url('../images/icons/lightning.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.06;
    }

    >* {
      position: relative;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .btn {
    margin-top: auto;
    max-width: none;
    padding-block: 10px;
  }

  h2 {
    margin: 0 auto rem(30);
    max-width: 800px;
    text-align: center;

    +p {
      display: block;
      margin: 0 auto rem(30);
      width: max-content;
      text-align: center;
    }
  }

  &--law {
    background-color: transparent;

    #{$root}__grid {
      grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    }

    #{$root}__item {
      font-weight: var(--fw-600);
      color: var(--color-white);
      background-color: var(--color-orange-400);
    }

    #{$root}__item {
      &::before {
        opacity: 0.1;
        transition: opacity 0.2s;
      }

      &:focus,
      &:hover {

        &::before {
          opacity: 0.2;
        }

        a {
          text-decoration: underline;
          color: var(--color-white);
        }
      }
    }

    a {
      position: static;
      display: flex;
      align-items: center;
      height: 100%;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
      }
    }

    h1 {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-m) {
    h2+p {
      width: auto;
    }

    &__grid {
      grid-template-columns: 1fr;
    }
  }
}