@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.about {
  $root: &;

  &__inner {
    display: grid;
    align-items: center;
    grid-template-columns: 590px 1fr;
    gap: rem(30);
  }

  &__content {
    text-align: justify;
    position: relative;
    z-index: 1;

    >*:not(:last-child) {
      margin-bottom: rem(30);
    }
  }

  &__picture {
    position: relative;
    justify-self: end;

    &.lightning {
      &::before {
        translate: -55% 0;

        @include media(desktop) {
          translate: -35% -15%;
        }

        @include media(tablet) {
          display: none;
        }
      }
    }
  }

  &--page {
    #{$root}__inner {
      align-items: start;
      margin-bottom: rem(40);
    }

    #{$root}__picture {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }

    #{$root}__docs {
      >*:not(:last-child) {
        margin-bottom: rem(30);
      }
    }

    #{$root}__docs-box {
      padding: 20px;
      font-weight: var(--fw-600);
      color: var(--color-white);
      background-color: var(--color-orange-400);

      p:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    h1 {
      text-align: left;
    }
  }


  @include media(tablet-wide) {
    &__inner {
      grid-template-columns: 400px 1fr;
    }
  }

  @include media(mobile-l) {
    &__inner {
      grid-template-columns: 1fr;
    }

    &__picture {
      margin: 0 auto;
    }
  }
}