@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.info-block {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  margin: 0 auto;
  border-radius: var(--radius-main);
  padding: 30px;
  max-width: 1280px;
  color: var(--color-white);
  background-color: var(--color-orange-400);
  translate: 0 -80%;

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(25);

    h3 {
      margin-bottom: 15px;
      font-weight: var(--fw-600);
      font-size: 24px;
    }
  }

  h2 {
    margin-bottom: 15px;
    font-size: 32px;
  }

  @include media(tablet) {
    translate: 0 -50%;
  }

  @include media(mobile-l) {
    translate: 0 -20%;

    &__list {
      grid-template-columns: 1fr;
    }
  }

  @include media(mobile-s) {
    translate: 0 -10%;
  }
}