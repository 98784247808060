@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.danger {
  text-align: justify;

  h2 {
    font-size: 26px;
  }

  ul {
    padding-left: 30px;
  }

  li {
    position: relative;

    &::before {
      @include contain-mask;

      content: "";
      position: absolute;
      left: -32px;
      top: 0;
      mask-image: url("../images/icons/lightning.svg");
      width: 25px;
      height: 25px;
      background-color: var(--color-orange-400);
      transform: skew(-16deg, -5deg);
    }
  }

  a {
    color: var(--color-orange-400);

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  >* {
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &--disputes {

    h2,
    p,
    ul {
      margin-bottom: 30px;
    }
  }

  &--call {
    .call-center {
      display: grid;
      grid-template-columns: 1fr;
      gap: rem(40);
    }

    h2 {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 30px;
    }

    p,
    ul {
      margin-bottom: 15px;
    }
  }

  @include media(tablet) {
    .call-center {
      grid-template-columns: 1fr;
    }
  }
}