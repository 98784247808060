@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.price {
  &__table {
    overflow-x: scroll;
    border: 1px solid var(--color-orange-400);

    thead {
      font-weight: var(--fw-500);
      color: var(--color-white);
      background-color: var(--color-orange-400);
    }

    td,
    th {
      padding: 18px 30px;
      width: 13%;

      p {
        text-align: center;
      }
    }

    tbody {
      font-weight: var(--fw-600);

      tr {
        border-top: 1px solid var(--color-orange-400);

        &:nth-child(even) {
          color: var(--color-white);
          background-color: var(--color-orange-400);
        }
      }
    }
  }

  &__table-body {
    table-layout: fixed;
    margin: 0 auto;
    border-collapse: collapse;
    width: 100%;
    min-width: 980px;
  }

  &__formula {
    margin: 20px 0;
    text-align: center;
  }

  &__text {
    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  >* {
    &:not(:last-child) {
      margin-bottom: rem(35);
    }
  }

  h1,
  .slogan {
    margin-bottom: rem(30);
  }

  .text-price {
    font-weight: var(--fw-700);
    text-align: center;
  }
}