@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.help {
  &__tabs {
    display: grid;
    grid-template-columns: 350px 1fr;
    gap: rem(30);
  }

  h1 {
    margin-bottom: rem(40);
  }

  a {
    color: var(--color-orange-400);

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  @include media(tablet-wide) {
    &__tabs {
      grid-template-columns: 270px 1fr;
    }
  }

  @include media(tablet) {
    &__tabs {
      grid-template-columns: 1fr;
    }

    h1 {
      margin-bottom: 20px;
    }
  }
}