@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.plans {
  &__header {
    margin-bottom: rem(50);

    >*:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__list {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(30);
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 30px;
    height: max-content;
    background-color: var(--color-gray-100);

    >*:not(:last-child) {
      display: block;
      margin-bottom: 20px;
    }

    p {
      padding: 15px;
      line-height: 1.6;
      background-color: var(--color-white);
    }

    &--third {
      grid-column: span 2;
    }
  }

  &__price {
    font-weight: var(--fw-700);
    font-size: clamp(1.25rem, 1.2rem + 0.5vi, 2rem);
    color: var(--color-orange-400);
  }

  >*:not(:last-child) {
    margin-bottom: rem(40);
  }

  @include media(tablet) {
    &__item {
      padding: 20px;
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &--third {
        grid-column: auto;
      }
    }
  }
}