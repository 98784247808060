@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  overflow: hidden;
  width: fit-content;
  font-weight: var(--fw-700);
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: clamp(2rem, 1.8rem + 1vi, 3rem);
}

h2,
.title {
  font-size: clamp(1.75rem, 1.6rem + 0.75vi, 2.5rem);

  @include media(mobile-s) {
    font-size: 24px;
  }
}

h3,
.title--sec,
.help h2 {
  font-size: clamp(1.2rem, 1rem + 0.2vi, 1.3rem);
}

a {
  transition: color 0.3s;

  &:hover,
  &:focus {
    color: var(--color-orange-400);
  }
}