@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.change {
  &__table {
    overflow-x: auto;

    td {
      border: 1px solid var(--color-orange-400);
      padding: 3px;
      text-align: center;
    }
  }

  &__table-body {
    display: table;
    overflow-x: scroll;
    margin: 0 auto;
    border-collapse: collapse;
    width: 100%;
    min-width: 1200px;
    text-align: left;
  }
}